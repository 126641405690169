<template>
  <div class="ComboboxSearchIcon">
    <Icon :icon="mapIcon.search" :size="16" />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.ComboboxSearchIcon {
  display: flex;
  align-items: center;
  height: 32px;
  flex-shrink: 0;
  color: var(--gray-3);
}
</style>
